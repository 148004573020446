import { ApiError, getErrorMessage } from 'fetcher-session';
import React, { useMemo } from 'react';
import { Message, MessageProps } from 'ui';

interface Props extends Omit<MessageProps, 'error'> {
  errors?: unknown | ApiError | ApiError[] | string | string[] | null;
  includeFieldErrors?: boolean;
}

const ErrorMessage: React.FC<Props> = ({
  errors,
  includeFieldErrors,
  ...props
}) => {
  const errorChildren = useMemo(() => {
    if (!errors) {
      return null;
    }

    if (typeof errors === 'string') {
      return errors;
    }

    if (Array.isArray(errors)) {
      // @ts-ignore
      return errors.filter(Boolean).map(e => {
        if (typeof e === 'string') {
          return <div key={e}>{e}</div>;
        }

        if ('errors' in e) {
          return e.errors
            ?.filter(e => (includeFieldErrors ? !e.field : true))
            .map(e => (
              <div key={e.message || e.detail}>{e.message || e.detail}</div>
            ));
        }

        const message = getErrorMessage(e);
        return <div key={message}>{message}</div>;
      });
    }
    // @ts-ignore
    if ('errors' in errors) {
      return (
        (errors as ApiError).errors
          ?.filter(Boolean)
          ?.filter(e => (includeFieldErrors ? !e.field : true))
          .map(e => (
            <div key={e.message || e.detail}>{e.message || e.detail}</div>
          )) ?? (errors as unknown as Error).message
      );
    }

    return <div>{getErrorMessage(errors)}</div>;
  }, [errors]);

  if (
    !errorChildren ||
    (Array.isArray(errorChildren) && errorChildren.length === 0)
  ) {
    return null;
  }

  return (
    <Message id="message-error" error {...props}>
      {errorChildren}
    </Message>
  );
};

export default ErrorMessage;
