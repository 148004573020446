import { RailsCategory } from '~/typings/services/rails/item';

export default function getCategorySlugsFromArray(
  categoriesArray: RailsCategory[],
): string[] {
  if (!(Array.isArray(categoriesArray) && categoriesArray.length > 0)) {
    return [];
  }

  return categoriesArray[0].slug.split('/');
}
