import { QueryClient } from '@tanstack/react-query';
import {
  ApiPaginatedResponse,
  fetcher,
  getBaseUrl,
  makeFetcher,
  Uuid,
} from 'fetcher-session';
import { NextPageContext } from 'next';
import { recaptchaHeader } from '~/api/auth';
import { LockerItemPageQuery } from '~/typings/pages/lockers';
import { RailsFacetQueryOptions } from '~/typings/services/rails/facets';
import { RailsCatalog } from '~/typings/services/rails/orders';
import { RailsProfile } from '~/typings/services/rails/profiles';
import { RailsFacetResponse } from '~/typings/services/rails/responses';
import {
  RailsFeedbackRating,
  RailsFollowUser,
  RailsUser,
  RailsUserFeedback,
} from '~/typings/services/rails/user';
import { LockerRouteQuery } from '../pages/locker/[username]/[itemState]';

export const fetchUserFavorites = (
  username: string,
  page: number | string = 1,
) =>
  fetcher<RailsFacetResponse>(`/v1/users/${username}/favorites`, {
    dataOnly: false,
    params: {
      page,
      sort: 'newest',
      src: 'locker',
    },
  });

export const fetchUserFollowers = makeFetcher<
  ApiPaginatedResponse<RailsFollowUser[]>
>((username: string, page: number | string = 1) => [
  `/v1/users/${username}/followers`,
  {
    dataOnly: false,
    params: {
      page,
      src: 'locker',
    },
  },
]);

export const fetchUserFollowing = makeFetcher<
  ApiPaginatedResponse<RailsFollowUser[]>
>((username: string, page: number | string = 1) => [
  `/v1/users/${username}/following`,
  {
    dataOnly: false,
    params: {
      page,
      src: 'locker',
    },
  },
]);

export const fetchLockerItems = makeFetcher<RailsFacetResponse>(
  ({ itemState, username, ...query }: LockerItemPageQuery) => [
    '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      dataOnly: false,
      params: {
        ...query,
        page_size: 24,
        seller: [username],
        src: 'locker',
        state: [itemState || 'available'],
      } as RailsFacetQueryOptions,
    },
  ],
);

export const fetchLockerItemsNoCache = makeFetcher<RailsFacetResponse>(
  ({ itemState, username, ...query }: LockerItemPageQuery) => [
    '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      dataOnly: false,
      params: {
        no_cache: 1,
        ignore_cache: 1,
        page_size: 24,
        ...query,
        seller: [username],
        src: 'locker',
        state: [itemState || 'available'],
      } as RailsFacetQueryOptions,
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
    },
  ],
);

export async function prefetchLockerItems(
  ctx: NextPageContext,
  query: LockerRouteQuery,
  queryClient: QueryClient,
  myLocker?: boolean,
) {
  return await queryClient.fetchQuery(['lockerItems', query, myLocker], () =>
    fetchLockerItemsNoCache.call(ctx, {
      ...query,
      q: query.q ? query.q + '*' : null,
      state: [query.itemState || 'available'],
      context: myLocker ? 'locker_owner' : 'locker_visitor',
    }),
  );
}

export const fetchLockerUserData = makeFetcher<RailsUser>(
  (username: string) => [
    `/v1/users/${username}`,
    {
      params: {
        src: 'locker',
      },
    },
  ],
);

export const fetchPublicProfile = (uuid: string) =>
  fetcher<RailsProfile>(`/profile/v1/users/${uuid}/profile`);

export const startFollowingUser = (them: number | Uuid) =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/following/${them}`, {
    method: 'put',
  });

export const stopFollowingUser = (them: number | Uuid) =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/following/${them}`, {
    method: 'delete',
  });

export const fetchUserFeedback = makeFetcher<
  ApiPaginatedResponse<RailsUserFeedback[]>
>(
  (
    username: string,
    rating?: RailsFeedbackRating,
    page: number | string = 1,
  ) => [
    `/v1/users/${username}/feedback`,
    {
      dataOnly: false,
      params: {
        page,
        rating: rating ? [rating] : undefined,
        src: 'locker',
      },
    },
  ],
);

export const fetchBumpsCatalog = () =>
  fetcher<RailsCatalog[]>('/v1/catalogs', {
    params: {
      type: ['bump'],
    },
  });

export const purchaseBumpsOrder = (
  catalogId: number,
  quantity: number,
  nonce: string,
  recaptchaToken: string,
) =>
  fetcher('/v1/orders', {
    method: 'post',
    body: {
      order: {
        nonce,
        quantity,
        catalog_id: catalogId,
      },
    },
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
  });

export const publishAll = () =>
  fetcher('/v1/items/publish', {
    method: 'post',
  });

export const addAllToSale = () =>
  fetcher('/v1/items/sale', {
    method: 'post',
  });

export const postExportItems = (query?: Record<string, any>) =>
  fetcher('/v1/items/export', {
    method: 'post',
    body: {
      query,
    },
  });
