import React from 'react';
import NewTabLink from '~/components/Links/NewTabLink';

export const helpLinks = {
  affiliates: 'https://help.sidelineswap.com/hc/en-us/articles/360016976000',
  ambassadors: '/ambassador/apply',
  badges: '/help/badges',
  bumps:
    'https://help.sidelineswap.com/hc/en-us/articles/360010361459-How-to-use-SidelineSwap-Bumps',
  buyerProtection: '/help/buyer-protection',
  buying: '/help/buying',
  careers: 'https://sidelineswap.recruitee.com/',
  canadianCashout:
    'https://help.sidelineswap.com/selling/cashing-out/if-i-have-a-canadian-bank-account-how-i-do-cash-out-in-usd',
  cashOutTiming: '/help/cash-out-timing',
  changeEmail:
    'https://help.sidelineswap.com/hc/en-us/articles/360010496320-How-to-Change-My-Email-Address-or-Username',
  communityGuidelines: '/help/community-guidelines',
  contactUs: '/help/contact-us',
  countryResidence: '/help/country-residence',
  customShippingCost:
    'https://help.sidelineswap.com/hc/en-us/articles/4873501778204',
  default: '/help',
  defaultForm:
    'https://help.sidelineswap.com/hc/en-us/requests/new?ticket_form_id=360000572220',
  donate: 'https://sell.sidelineswap.com/donate/',
  discountedShipping:
    'https://help.sidelineswap.com/hc/en-us/articles/4410656215314-How-does-free-and-discounted-shipping-work-',
  eliteSeller:
    'https://help.sidelineswap.com/hc/en-us/articles/360010255880--Elite-Seller-Program',
  howDoIGetPaid: '/help/how-do-i-get-paid',
  howToTrade: '/help/how-to-trade',
  ims: 'https://help.sidelineswap.com/hc/en-us/articles/4412819019922',
  informAct:
    'https://help.sidelineswap.com/hc/en-us/articles/7831970783260-INFORM-Act-2023',
  internationalDelays: '/help/international-delays',
  myFeed: '/help/my-feed',
  partialRefunds:
    'https://help.sidelineswap.com/hc/en-us/articles/360016231220-How-to-issue-a-partial-refund',
  press: '/help/press',
  productRankings:
    'https://help.sidelineswap.com/hc/en-us/articles/4402828329106',
  prohibitedItems: '/help/prohibited-items',
  refundPolicy: '/help/refund-policy',
  refundRateImportance:
    'https://help.sidelineswap.com/hc/en-us/articles/360013927139',
  safety: '/help/safety',
  sales: '/help/sales',
  selling: '/help/selling',
  shipping: '/help/shipping',
  shippingInsurance:
    'https://help.sidelineswap.com/hc/en-us/articles/9098057556892-Insuring-your-Items',
  shippingRegions: '/help/shipping-regions',
  sidelinePro: '/help/sideline-pro',
  sidelineProtection: '/help/sidelineswap-protection',
  signIn: '/help/sign-in',
  submitRequestForm:
    'https://help.sidelineswap.com/hc/en-us/requests/new?ticket_form_id=360000525400',
  supportedDevices: '/help/supported-devices',
  taxes: '/help/taxes',
  tradeInGuidelines: 'https://sidelineswap.com/blog/trade-in-guidelines/',
  underReview: '/help/under-review',
  unrestrictForm:
    'https://help.sidelineswap.com/hc/en-us/requests/new?ticket_form_id=360000525400',
  usernameChangeForm:
    'https://help.sidelineswap.com/hc/en-us/requests/new?ticket_form_id=360000525400',
  whatAreTheFees: '/help/what-are-the-fees',
  whySsn: '/help/ssn-photo-id',
  w9: 'https://help.sidelineswap.com/hc/en-us/articles/360010269400-1099-K-Forms-for-Sellers',
  zipPay: 'https://help.sidelineswap.com/hc/en-us/articles/4407009127314',
};

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  link?: keyof typeof helpLinks;
};

const HelpLink: React.FC<Props> = ({
  children,
  link = 'default',
  ...props
}) => (
  <NewTabLink {...props} href={helpLinks[link]} rel="nofollow noindex">
    {children}
  </NewTabLink>
);

export default HelpLink;
