import { RailsCategory } from '~/typings/services/rails/item';
import getCategorySlugsFromArray from '~/utils/getCategorySlugsFromArray';

export function categorySlugAnalytics(categorySlug: string) {
  return categorySlug
    .split('/')
    .reduce((acc, slug, i) => ({ ...acc, [`category_${i + 1}`]: slug }), {});
}

export default function categoryAnalytics(categories: RailsCategory[]) {
  if (!categories) {
    return { category_1: '', category_2: '' };
  }

  return getCategorySlugsFromArray(categories).reduce<{
    category_1: string;
    category_2: string;
    category_3?: string;
  }>(
    (prev, cur, i) => ({
      ...prev,
      [`category_${i + 1}`]: cur,
    }),
    { category_1: '', category_2: '' },
  );
}
