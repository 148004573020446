import { QueryClient } from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';
import { fetchPublicProfile } from '~/api/locker';
import {
  AnalyticsSource,
  GAPageType,
} from '~/services/analytics/AnalyticsInterfaces';
import buildItemAnalytics from '~/services/analytics/buildItemAnalytics';
import { BaseCheckoutEventParams } from '~/services/analytics/events/checkout';
import fireAnalytics from '~/services/analytics/fireAnalytics';
import fireFacebookEvent from '~/services/analytics/fireFacebookEvent';
import fireGAEvent from '~/services/analytics/fireGAEvent';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';
import getUTMsFromCookies from '~/services/analytics/getUTMsFromCookies';
import { isFirstVisit } from '~/services/analytics/identify';
import { Cart } from '~/typings/services/rails/cart';
import {
  RailsItem,
  RailsShipping,
  RailsThumbnailItem,
} from '~/typings/services/rails/item';
import { RailsAddress } from '~/typings/services/rails/session';

export const visitedDetailGuidance = (
  context: 'list' | 'shop',
  src: AnalyticsSource,
  detailSlug?: string,
  categorySlug?: string,
) =>
  fireAnalytics('Visited Detail Guidance', src, {
    context,
    detailSlug,
    categorySlug,
  });

interface VisitedItemParams {
  item: RailsItem;
  username?: string;
  src?: AnalyticsSource;
}

export const visitedUnknownItem = (itemId: number | undefined | null) => {
  fireGtagEvent('event', 'view_item', {
    page_type: 'Unavailable Item - Unknown' satisfies GAPageType,
    items: itemId
      ? [
          {
            item_id: itemId,
          },
        ]
      : undefined,
  });
};

export const visitedItem = async ({
  item,
  username,
  src,
}: VisitedItemParams) => {
  const { categories, model } = item;

  const brand = item.details.find(d => d.slug === 'brand')?.values?.[0];

  let shipDays: number | undefined;
  try {
    const profileData = await fetchPublicProfile(item.seller.username);
    shipDays = profileData.ship_days;
  } catch (e) {}

  const tracking: Record<string, any> = {
    ...buildItemAnalytics(item),
    auction: item.auction,
    brand_id: brand?.id,
    brand_name: brand?.name,
    category: categories?.[0]?.slug,
    name: item.name,
    model_id: model?.id,
    model_name: model?.name,
    price: item.price,
    role: item.seller.username === username ? 'seller' : null,
    seller_username: item.seller.username,
    ship_days: shipDays,
    sport: categories?.[0]?.full_name.split(' > ')[0],
    state: item.state,
  };

  if (item.estimated_delivery?.max_delivery_date) {
    tracking.max_delivery_days = differenceInDays(
      Date.now(),
      new Date(item.estimated_delivery.max_delivery_date),
    );
  }

  if (item.seller && item.seller.feedback) {
    tracking.feedback_score = item.seller.feedback.score;
    tracking.feedback_count = item.seller.feedback.recent_count;
  }

  if (item.sale) {
    tracking.sale = item.sale.slug;
  }

  if (!!username) {
    tracking.no_offer_message = !!username;
  }

  fireAnalytics('Visited Item', src, tracking);

  fireGAEvent('set', 'contentGroup2', item.state);
  fireGAEvent('send', 'event', 'Item', 'Visited Item', {
    nonInteraction: true,
  });

  let pageType: GAPageType;

  if (item.state === 'available') {
    pageType = 'Available Item';
  } else if (item.state === 'sold') {
    pageType = 'Unavailable Item - Sold';
  } else if (item.state === 'removed') {
    pageType = 'Unavailable Item - Removed';
  } else if (item.state === 'archived') {
    pageType = 'Unavailable Item - Archived';
  } else {
    pageType = 'Unavailable Item - Unknown';
  }

  fireGtagEvent('event', 'view_item', {
    page_type: pageType,
    first_visit: isFirstVisit(),
    currency: 'USD',
    value: item.price,
    items: [
      {
        ...item.categories[0]?.full_name.split(' > ').reduce((acc, cur, i) => {
          if (i == 0) {
            return { ...acc, item_category: cur };
          }

          return { ...acc, [`item_category${i + 1}`]: cur };
        }, {}),
        google_business_vertical: 'retail',
        id: item.id,
        item_brand: item.details?.find(d => d.slug === 'brand')?.values?.[0]
          ?.name,
        item_id: item.id,
        item_name: item.name,
        list_item_id: item.id,
        list_item_name: item.name,
        quantity: item.quantity,
      },
    ],
  });

  fireFacebookEvent('track', 'ViewContent', {
    content_category: item.categories[0]?.full_name,
    content_ids: [String(item.id)],
    content_name: item.name,
    content_type: 'product',
    currency: 'USD',
    value: 0,
  });
};

export const expandedItemSection = (
  type: 'description' | 'faq' | 'buying_guides' | 'profile',
  itemId: number,
) =>
  fireAnalytics('Expanded Item Section', null, {
    type,
    item_id: itemId,
  });

export const collapsedItemSection = (
  type: 'description' | 'faq' | 'buying_guides' | 'profile',
  itemId: number,
) =>
  fireAnalytics('Collapsed Item Section', null, {
    type,
    item_id: itemId,
  });

export const fireAddedToCart = (item: RailsItem, queryClient: QueryClient) => {
  fireAnalytics('Added to Cart', 'item', {
    ...buildItemAnalytics(item),
    cart_id: queryClient.getQueryData<Cart>(['cart'])?.id,
  });

  fireGtagEvent('event', 'add_to_cart', {
    google_business_vertical: 'retail',
    id: item.id,
    value: item.price,
  });
};

// TODO: This should fire in the modal. Currently it's in usePurchaseItem
export const visitedCheckoutOffer = (item: RailsItem, buyNow: boolean) =>
  fireAnalytics('Visited Checkout Offer', 'item', {
    ...buildItemAnalytics(item),
    auction: item.auction,
    buy_now: buyNow,
  });

export const favoritedItem = (
  item: RailsItem | RailsThumbnailItem,
  src: AnalyticsSource = 'item',
) => fireAnalytics('Favorited Item', src, { ...buildItemAnalytics(item) });

export const unfavoritedItem = (
  item: RailsItem | RailsThumbnailItem,
  src: AnalyticsSource = 'item',
) => fireAnalytics('Unfavorited Item', src, { ...buildItemAnalytics(item) });

export const removedItem = (itemId: number) =>
  fireAnalytics('Removed Item', null, { item_id: itemId });

export const fireInitiatedItem = ({ src }: { src?: AnalyticsSource }) =>
  fireAnalytics('Initiated Item', src);

export const clickedBump = ({
  availableBumpCount,
  src,
}: {
  availableBumpCount?: number;
  src?: AnalyticsSource;
}) =>
  fireAnalytics('Clicked Bump', src, {
    available_bump_count: availableBumpCount,
  });

export const fireEmailCaptured = ({
  email,
  pageName,
  zip,
  src,
}: {
  email: string;
  pageName?: string;
  zip?: string;
  src?: AnalyticsSource;
}) =>
  fireAnalytics('Email Captured', src, {
    email,
    zip,
    page_name: pageName,
  });

export const visitedPublishedModal = (itemId: number) =>
  fireAnalytics('Visited Published Modal', null, {
    item_id: itemId,
  });

export const visitedFavoritesGuidance = (itemId: number) =>
  fireAnalytics('Visited Favorites Guidance', null, {
    item_id: itemId,
  });

export const visitedBundleGuidance = (itemId: number) =>
  fireAnalytics('Visited Bundle Guidance', null, {
    item_id: itemId,
  });

export interface InitiatedCheckoutEventParams extends BaseCheckoutEventParams {
  has_address?: boolean;
  has_payment?: boolean;
}

export const fireInitiatedCheckout = (
  item: RailsItem,
  loggedIn: boolean,
  buyNow: boolean,
  queryClient: QueryClient,
) => {
  const addresses =
    queryClient.getQueryData<RailsAddress[] | undefined>(['addresses']) || [];

  // Warning, this does not account for ship_to_(country|zip) cookies
  const shipping =
    queryClient.getQueryData<RailsShipping | undefined>([
      'shipping',
      item.id,
      addresses?.find(a => a.flags?.ship_to)?.id,
    ])?.shipping || 0;

  const params: InitiatedCheckoutEventParams = {
    ...buildItemAnalytics(item),
    ...getUTMsFromCookies(),
    shipping,
    auction: item.auction,
    buy_now: buyNow,
    cart: false,
    platform: 'WEB',
    guest_checkout: !loggedIn,
    has_address: addresses.length > 0,
  };

  fireAnalytics('Initiated Checkout', null, params);
};
