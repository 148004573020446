import React from 'react';
import { Input, InputProps, PasswordInput } from './Input';
import { clsx } from 'clsx';

export type BaseFieldProps = {
  containerClassName?: string;
  error?: React.ReactNode;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  labelClassName?: string;
};

export type FieldProps = Omit<InputProps, 'error'> & BaseFieldProps;

interface FieldLabelProps {
  children?: React.ReactNode;
  htmlFor?: string;
  id?: string;
  className?: string;
  error?: React.ReactNode | boolean;
  name?: string;
}

export const Label = React.forwardRef<HTMLLabelElement, FieldLabelProps>(
  ({ htmlFor, id, name, children, className, error }, ref) => (
    <label
      ref={ref}
      className={clsx(
        'block pb-1 text-sm font-semibold',
        error && 'text-red-500',
        className,
      )}
      htmlFor={htmlFor || id || name}
    >
      {children}
    </label>
  ),
);

interface FieldErrorProps {
  className?: string;
  name?: string;
  id?: string;
}

export const FieldError: React.FC<React.PropsWithChildren<FieldErrorProps>> = ({
  className,
  children,
  id,
  name,
}) => (
  <div
    className={clsx('mt-1 text-xs text-red-500', className)}
    id={`${id || name}-error`}
  >
    {children}
  </div>
);

interface FieldHintProps extends React.HTMLAttributes<HTMLDivElement> {}

export const FieldHint: React.FC<React.PropsWithChildren<FieldHintProps>> = ({
  children,
  className,
  ...props
}) => (
  <div
    className={clsx(className, 'text-slate-green-500 mt-1 text-xs')}
    {...props}
  >
    {children}
  </div>
);

export const Field = React.forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      children,
      containerClassName,
      error,
      hint,
      id: baseId,
      label,
      labelClassName,
      name,
      ...props
    },
    ref,
  ) => {
    const id = baseId || name;
    return (
      <div className={clsx('mb-4', containerClassName)}>
        {label && (
          <Label
            htmlFor={id || name}
            name={name}
            error={error}
            className={labelClassName}
          >
            {label}
          </Label>
        )}

        {React.Children.count(children) > 0 ? (
          React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                error,
              });
            }
          })
        ) : (
          <Input
            error={!!error}
            id={id}
            name={name}
            {...props}
            aria-describedby={
              error ? `${id}-error` : hint ? `${id}-hint` : undefined
            }
            ref={ref}
          />
        )}

        {hint && <FieldHint id={`${id}-hint`}>{hint}</FieldHint>}

        {error && <FieldError id={id}>{error}</FieldError>}
      </div>
    );
  },
);

export const PasswordField = React.forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      children,
      containerClassName,
      error,
      hint,
      id: baseId,
      label,
      labelClassName,
      name,
      ...props
    },
    ref,
  ) => {
    const id = baseId || name;
    return (
      <div className={clsx('mb-4', containerClassName)}>
        {label && (
          <Label
            htmlFor={id || name}
            name={name}
            error={error}
            className={labelClassName}
          >
            {label}
          </Label>
        )}

        {React.Children.count(children) > 0 ? (
          React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                error,
              });
            }
          })
        ) : (
          <PasswordInput
            error={!!error}
            id={id}
            name={name}
            {...props}
            aria-describedby={
              error ? `${id}-error` : hint ? `${id}-hint` : undefined
            }
            ref={ref}
          />
        )}

        {hint && <FieldHint id={`${id}-hint`}>{hint}</FieldHint>}

        {error && <FieldError id={id}>{error}</FieldError>}
      </div>
    );
  },
);
