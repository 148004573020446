import React from 'react';
import { useField } from 'formik';
import { FieldError } from 'ui';

interface Props {
  className?: string;
  name: string;
}

export const FormikFieldError: React.FC<Props> = ({ className, name }) => {
  const [field, meta] = useField(name);

  return (
    <FieldError className={className} name={name}>
      {meta.error}
    </FieldError>
  );
};
