import { clsx } from 'clsx';
import React from 'react';

type ColorType =
  | 'primary'
  | 'turf-green'
  | 'slate-green'
  | 'green'
  | 'black'
  | 'blue'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'darkgrey'
  | 'grey';

export type CustomButtonProps = {
  color?: ColorType;
  disabled?: boolean;
  className?: string;
  fluid?: boolean;
  size?: 'tiny' | 'small' | 'normal' | 'no-padding' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
  ref?: React.Ref<HTMLButtonElement>;
};

export function generateButtonClassNames({
  className,
  color = 'primary',
  disabled,
  fluid,
  size = 'normal',
  variant = 'contained',
}: CustomButtonProps) {
  const disabledCx =
    'disabled:cursor-not-allowed disabled:text-pastel-green-500 disabled:bg-off-white-500 disabled:hover:bg-off-white-500 disabled:border disabled:border-grey-500 disabled:hover:border-grey-500';

  const variantCx =
    variant === 'contained'
      ? [
          'border hover:shadow focus:ring',
          disabledCx,
          {
            'bg-turf-green-500 border-turf-green-500 text-white hover:bg-turf-green-900 hover:border-turf-green-900':
              color === 'primary' || color === 'turf-green',
            'bg-slate-green-500 border-slate-green-500 text-white hover:bg-slate-green-600 hover:border-slate-green-600':
              color === 'slate-green',
            'bg-green-500 border-green-500 text-white hover:bg-green-600 hover:border-green-600':
              color === 'green',
            'bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white':
              color === 'blue',
            'bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-600 text-white':
              color === 'danger',
            'bg-yellow-500 border-yellow-500 hover:bg-yellow-600 hover:border-yellow-600 text-white':
              color === 'warning',
            'bg-darkgrey-500 border-darkgrey-500 hover:bg-darkgrey-600 hover:border-darkgrey-600 text-white':
              color === 'darkgrey' || color === 'secondary',
            'bg-white hover:bg-grey-100 text-turf-green-500':
              color === 'grey' || color === 'black',

            'dark:bg-grey-600 dark:hover:bg-grey-700 dark:text-grey-300 dark:border-grey-600 dark:hover:border-grey-700':
              color === 'primary' || color === 'turf-green',
          },
        ]
      : variant === 'outlined'
        ? [
            'border focus:ring',
            disabledCx,
            {
              'border-turf-green-500 hover:bg-turf-green-100 text-turf-green-500':
                color === 'primary' || color === 'turf-green' || !color,
              'border-blue-500 hover:bg-blue-100 text-blue-500':
                color === 'blue',
              'border-green-500 hover:bg-green-100 text-green-500':
                color === 'green',
              'border-red-500 hover:bg-red-100 text-red-500':
                color === 'danger',
              'border-yellow-500 hover:bg-yellow-100 text-yellow-500':
                color === 'warning',
              'border-darkgrey-500 hover:bg-darkgrey-100 text-darkgrey-500':
                color === 'darkgrey' || color === 'secondary',
              'hover:bg-grey-100 text-turf-green-500':
                color === 'grey' || color === 'black',
            },
          ]
        : variant === 'text'
          ? [
              'text-turf-green-500 focus:outline-none disabled:text-pastel-green-500 focus:ring',
            ]
          : null;
  return clsx(
    'appearance-none rounded font-semibold transition duration-200',
    {
      'px-2 py-1 text-xs': size === 'tiny',
      'px-4 py-1.5 text-sm': size === 'small',
      'px-6 py-2 text-sm': size === 'normal',
      'px-6 py-3 text-sm': size === 'large',
      'w-full flex-1': fluid,
    },
    disabled ? disabledCx : variantCx,

    // variant === 'text' && 'border-0',
    className,
  );
}

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  CustomButtonProps & {
    as?: string | React.ComponentType;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, color, disabled, fluid, size, variant, ...props },
    ref,
  ) => {
    const Element = props.as || 'button';

    return (
      // @ts-ignore
      <Element
        className={generateButtonClassNames({
          className,
          color,
          disabled,
          fluid,
          size,
          variant,
        })}
        type={props.type || 'button'}
        disabled={disabled}
        {...props}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </Element>
    );
  },
);

type LegacyButtonLinkProps = Omit<ButtonProps, 'as'> & {
  as: React.ReactElement;

  /**
   * Currently, for some reason, React.cloneElement and Styled Components `css` prop
   * do not get along. This causes some weird internal React error, so don't do it until
   * it may be fixed or better supported.
   */
  css?: never;
};

export const LegacyButtonLink: React.FC<LegacyButtonLinkProps> = ({
  as,
  children,
  disabled,
  ...props
}) => {
  const button = (
    <Button {...props} disabled={disabled} tabIndex={-1}>
      {children}
    </Button>
  );

  if (disabled) {
    return button;
  } else {
    return React.cloneElement(React.Children.only(as), {}, button);
  }
};

export const ButtonAnchor = React.forwardRef<
  HTMLAnchorElement,
  CustomButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ children, className, disabled, ...props }, ref) => {
  if (disabled) {
    return (
      // @ts-ignore
      <Button {...props} ref={ref} disabled={disabled}>
        {children}
      </Button>
    );
  }

  return (
    <a
      {...props}
      className={generateButtonClassNames({
        ...props,
        className: clsx(className, 'inline-block text-center'),
      })}
      ref={ref}
    >
      {children}
    </a>
  );
});

export const ButtonNewTabLink = React.forwardRef<
  HTMLAnchorElement,
  CustomButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ children, className, disabled, ...props }, ref) => {
  if (disabled) {
    return (
      // @ts-ignore
      <Button {...props} ref={ref} disabled={disabled}>
        {children}
      </Button>
    );
  }

  return (
    <a
      {...props}
      className={generateButtonClassNames({
        ...props,
        className: clsx(className, 'inline-block text-center'),
      })}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
    >
      {children}
    </a>
  );
});
