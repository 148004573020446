import categoryAnalytics from '~/services/analytics/catagoryAnalytics';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import { RailsSale } from '~/typings/services/rails/sales';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';

interface ItemAnalytics {
  category?: string;
  category_1?: string;
  category_2?: string;
  category_3?: string;
  item_id: number[];
  list_price: number;
  price: number;
  sale?: string;
}

export default function buildItemAnalytics(
  item: RailsItem | RailsThumbnailItem | RailsSwapDetail['item'],
  sale: null | string | RailsSale = null,
): ItemAnalytics {
  const itemAnalytics: ItemAnalytics = {
    ...('categories' in item && categoryAnalytics(item.categories)),
    category: 'categories' in item ? item.categories?.[0]?.slug : undefined,
    item_id: [item.id],
    list_price: item.price,
    price: item.price,
  };

  if ('sale' in item && sale) {
    itemAnalytics.sale = typeof sale === 'string' ? sale : sale.slug;
  }

  return itemAnalytics;
}
