import { thousandsSeparator } from './thousandsSeparator';

const formatterDecimals =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  });

const formatterNoDecimals =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currency: 'USD',
    style: 'currency',
  });

/**
 * Adds dollar sign and formats the price
 * @param {number} price
 * @param {boolean} decimals
 */
export function displayPrice(
  price: string | number | null | undefined,
  decimals = true,
) {
  if (price == null) return '$--';

  if (decimals && formatterDecimals) {
    return formatterDecimals.format(+price);
  }

  if (!decimals && formatterNoDecimals) {
    return formatterNoDecimals.format(+price);
  }

  let newPrice: string | number = Number(price);

  let formattedPrice = '';
  if (Number(price) < 0) {
    formattedPrice = '- ';
    newPrice = Math.abs(Number(price));
  }

  if (!decimals) {
    newPrice = thousandsSeparator(Math.floor(newPrice));
  } else {
    newPrice = thousandsSeparator(newPrice, 2);
  }

  formattedPrice += `$${newPrice}`;
  return formattedPrice;
}
