import React from 'react';
import { AnchorProps } from '~/components/Links/A';

type Props = AnchorProps & { href?: string };

const NewTabLink: React.FC<Props> = ({ children, rel, ...props }) => (
  <a
    {...props}
    target="_blank"
    rel={`noopener noreferrer${rel ? ' ' + rel : ''}`}
  >
    {children}
  </a>
);

export default NewTabLink;
